import React, {Component, useEffect} from 'react';
import _ from 'lodash';
import * as echarts from 'echarts';
import {
  getMetricFormatter,
  intFormatter,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  uniquesFormatter
} from "../utils/formatter";
import styled from "styled-components";
import {
  Aladin_Blue_Light, Aladin_Orange_Dark,
  Aladin_Orange_Light, Aladin_Purple_Dark, Aladin_Purple_Light, Aladin_Red_Light, Aqua, Aqua1,
  BitterSweet,
  BlueJeans, BlueJeans0, BlueJeans1, DarkGray,
  GrapeFruit,
  Grass,
  Grass1,
  MediumGray1,
  Mint, Sunflower
} from "../app/StyleCommon";
import {dimensionValueMap, platformLabelMap, siteLabelMap} from "../utils/metadata";
import {getContextChartScale} from "./util";
import {descendOtherAndUnknown} from "../utils/misc";
import {NoDataComponent} from "./noDataComponent";

export default class extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(this.props) !== JSON.stringify(prevProps) || JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.renderChart();
    }
  }

  render() {
    let {data, cfg, currency} = this.props;
    if(!data || data.every(d => !d.value && !d.actual )) {
      return <NoDataComponent {...this.props} />
    }
    return (
      <ChartWrapper className="chart">
        <div ref={this.containerRef}/>
      </ChartWrapper>
    )
  }

  renderChart() {
    if(!this.containerRef.current) {
      return;
    }
    let {data, chart, cfg, context, currency} = this.props;
    const {metric, dimension, pageSize} = cfg;
    const metricMeta = context.mixedMetaData.find(d => d.key === metric);
    if((data ||[]).every(d => !d.value && !d.actual)) {
      return null;
    }
    data = _.cloneDeep(data);
    if(dimension === 'platform') {
      data = data.filter(d => ['social', 'ooh', 'others'].indexOf(d.key) < 0); //filter platforms not yet supported
    }
    if(chart.key === 'breakdown_platform_pie') {
      data = data.filter(d => d.key !== 'overall');
    }
    data.forEach(d => {
      if(d.label) {
        d.label = d.label + ` (${d.key})`;
      } else {
        d.label = siteLabelMap[d.key] || platformLabelMap[d.key] || d.key;
      }
      d.name = d.label;
      d.value = d.value || d[metric] || d.actual || 1;
    })
    data = _.sortBy(data, _.property('value'));
    if(pageSize !== 'all') {
      let isOther = d => d.key === `others_${metric}` || d.key === 'others'
      let others = data.find(d => isOther(d));
      data = data.filter(d => !isOther(d)).slice(0, 10);
      if(others) {
        data.push(others);
      }
    }
    data = data.reverse();
    data = descendOtherAndUnknown(data);
    const sumOfValue = _.sumBy(data, d => Number(d.value) || 0);
    const container = this.containerRef.current;
    let width = undefined;
    if(getContextChartScale() !== 1) {
      width = this.containerRef.current.parentElement.offsetWidth * getContextChartScale();
    }
    let height = Math.max(this.containerRef.current.parentElement.offsetHeight || 0, 260);
    const myChart = echarts.init(container, null, {renderer: 'canvas', width, height});
    let option = {
      textStyle: {fontFamily: 'FKGroteskNeue, "Open Sans", sans-serif'},
      // color: ['#F66D44', '#FEAE65', '#E6F69D', '#AADEA7', '#64C2A6', '#2D87BB', '#3F9F9F', '#A2C3DB', "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"],
      color: ['#f1a164', '#f4c168','#ec685c',  '#436265', '#4f8f98', '#6eb3c4','#2D6A4F', '#40916C','#532B88', '#9B72CF', Aqua1, Aqua, Grass1, Grass1, BlueJeans, BlueJeans0, BlueJeans1],
      tooltip: {
        trigger: 'item',
        position: 'top',
        confine: true,
        extraCssText: 'max-width:auto; white-space:pre-wrap;',
        valueFormatter: function (value) {
          return metricMeta.formatter(value);
          // let value = d.value;
          // if(value.length > 18){
          //   return value.substring(0, 15) + '...';
          // }
          // return value;
        }
      },
      grid: {
        top: '20',
        left: '10',
        right: '40',
        bottom: '5',
        containLabel: true,
      },
      legend: {
        left: 20,
        top: 'center',
        orient: 'vertical',
        type: 'scroll',
        itemWidth: 12 ,
        itemHeight: 10 ,
        pageIconSize: '8',
        pageIconColor: Aladin_Orange_Dark,
        pageTextStyle: {
          lineHeight: 56,
          color: Aladin_Orange_Dark
        },
        itemStyle: {
          fontSize: 10,
        },
        tooltip: {
          show: true,
          trigger: 'item',
          position: 'top',
          formatter: function (params) {
            // console.log(params)
            const item = data.find(d => d.label === params.name || d.key === params.name) || {};
            return `<strong>${params.name}</strong><br/> ${metricMeta.label}: <strong>${metricMeta.formatter(item.value)}</strong>`;
          }
        },
        color: DarkGray,
        overflow: 'ellipsis',
        fontSize: 12,
        formatter: function (name) {//use formatter to check if length of string is greater than 20 and maintain its width.
          let item = data.find(d => d.key === name || d.label === name) || {};
          name = item.label || siteLabelMap[String(name)] || name;
          // let str = (platformLabelMap[name] || name) + ` (${getMetricFormatter(metricMeta, currency)(item.value)})`;
          let str = (platformLabelMap[name] || name);
          if(str.length > 18){
            str =  str.substring(0, 15) + '...';
          }
          let sumValue = _.sum(data.map(d => Number(d[metric] || 0)));
          if(item[`total_${metric}`]) {
            return str +  ' (' + pctFormatter(item.value / sumValue) + ')';
          } else if(metricMeta.accumulatable) {
            return str +  ' (' + (pctFormatter(item.value / sumOfValue)) + ')';
          }  else {
            return str +  ' (' + (metricMeta.formatter(item.value)) + ')';
          }
        }
      },
      series: [
        {
          type: 'pie',
          data: data.filter(d => d.key !== 'overall'),
          left: '50%',
          label: {
            position: 'inner',
            fontSize: 12,
            color: '#605e5e',
            backgroundColor: 'rgba(255,255,255,.5)',
            borderRadius: 5,
            padding: [2, 3, 3, 2],
            formatter: d => {
              let item = data.find(item => item.key === d.name) || {};
              let str = item.label || dimensionValueMap[d.name] || d.name || '';
              if(str.length > 10){
                return str.substring(0, 10) + '...';
              }
              return str;
            }
          },
        }
      ]
    };

    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}

export const ChartWrapper = styled.div`
  width: 100%;
`;
