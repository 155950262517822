import React, {Component, useEffect} from 'react';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';
import {ReRenderHook} from '../utils/hooks';
import {
  Aladin_Blue_Dark,
  Aladin_Blue_Light, Aladin_Orange_Dark, Aladin_Orange_Light, Aladin_Purple_Dark, Aladin_Red_Dark,
  BitterSweet,
  BlueJeans,
  BlueJeans1,
  DarkGray, DarkGray1,
  Grass,
  Grass1,
  LightBlue,
  MediumGray,
  colorsAladdin, Aqua1, Aqua, BlueJeans0
} from "../app/StyleCommon";
import {getMetricFormatter, intFormatter, moneyFormatter, pctFormatter, uniquesFormatter} from "../utils/formatter";
import classnames from "classnames";
import {UnitItem} from "../charts/timeseries";
import styled from "styled-components";
import {platformLabelMap} from "../utils/metadata";

export default class extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {unit: 'monthly'};
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart();
  }

  render() {
    const {unit} = this.state;
    return (
      <ChartWrapper>
        <div className="chart-header">
          <div className="chart-legent">
          </div>
        </div>
        <div ref={this.containerRef}/>
      </ChartWrapper>
    )
  }

  renderChart() {
    let {data} = this.props;
    const container = this.containerRef.current;
    const myChart = echarts.init(container, null, {renderer: 'svg', height: 300});
    const colors = colorsAladdin; //[Aladin_Orange_Light, Aladin_Blue_Dark];
    data.forEach(d => {
      // d.msqCount = d.msq * _.random(5, 20);
      // d.campaignCount = d.msq * _.random(2, 5);
      d.msqCount = d.msq;
      d.campaignCount = d.campaign;
    })
    let formatterCounter = -1;
    let option = {
      // textStyle: {fontFamily: 'sans-serif, "Open Sans"'},
      textStyle: {fontFamily: 'FKGroteskNeue, "Open Sans", sans-serif'},
      color: ['#f1a164', '#f4c168','#ec685c',  '#4f8f98','#6eb3c4', '#b7b8b9', '#2D6A4F', '#40916C','#532B88', '#9B72CF', Aqua1, Aqua, Grass1, Grass1, BlueJeans, BlueJeans0, BlueJeans1],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        valueFormatter: (value) => {
          formatterCounter++;
          if(formatterCounter % 3 === 0) {
            return moneyFormatter(value, 'SGD');
          }
          else {
            return intFormatter(value)
          }
        },
        formatter: (params) => {
          // console.log(params);
          let first = params[0];
          let {breakdown = {}, msqCount, campaignCount} = data[first.dataIndex] || {};
          return `
<div style="min-width: 200px">
            <div><strong>${first.name}:</strong> (${msqCount} MSQs, ${campaignCount} Onboarded Aladdin Campaign)</div>
            ${params.map((d, axisIndex) => {
              if(axisIndex > 0 && axisIndex < 7 ) return '';
              let formatter = axisIndex === 0 ? moneyFormatter: intFormatter;
              breakdown = axisIndex === 0 ? breakdown: {};
                return `
                  <div>${d.marker} ${d.seriesName} Revenue: <strong>${formatter(d.value)}</strong></div>
                  ${_.sortBy(Object.keys(breakdown), k => -breakdown[k]).filter(k => k !== 'undefined').map(k => `
                       <div style="margin-left: 20px; font-size: 11px"> - ${platformLabelMap[k]}: ${formatter(breakdown[k])} (${pctFormatter(breakdown[k] / d.value)})</div>
                  `).join('')}
                `
            }).join('')}
</div>
          `
        }
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {name: 'my_chart'},
      //     dataView: {},
      //     magicType: {}
      //   },
      // },
      legend: {
        left: 0,
        type: 'scroll',
      },
      grid: {
        top: 70,
        bottom: 50,
        left: 70,
        right: 38,
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              // color: colors[0],
              fontWeight: 'bold'
            }
          },
          axisPointer: {
            label: {
              // formatter: function (params) {
              //   return (
              //     params.value +
              //     (params.seriesData.length ? '：' + params.seriesData[0].data : '')
              //   );
              // }
            }
          },
          // prettier-ignore
          data: data.map(d => moment(d.date).format('YYYY-MMM'))
        }
      ],
      yAxis: [
        {
          type: 'value',
          splitLine: {show: false},
          position: 'left',
          splitNumber: 2,
          name: 'Revenue',
          nameLocation: 'start',
          nameGap: 30,
          min: 0,
          max: _.max(data.map(d => d.budget)) * 1,
          axisTick: {
            inside: false, length: 5
          },
          nameTextStyle: {
            // color: '#f36610',
          },
          axisLabel: {
            inside: true,
            margin: -50,
            formatter: value => {
              return '$' + uniquesFormatter(value)
            }
          }
        },
        // {
        //   name: 'No. Of MSQs',
        //   nameLocation: 'start',
        //   nameGap: 30,
        //   offset: 10,
        //   min: 0,
        //   max: _.max(data.map(d => d.msqCount)) * 1.1,
        //   nameTextStyle: {
        //     // color: '#e8a500',
        //     align: 'right'
        //   },
        //   type: 'value',
        //   splitLine: {show: true},
        //   position: 'right',
        //   splitNumber: 2,
        //   axisTick: {
        //     inside: true, length: 5
        //   },
        //   axisLabel: {
        //     inside: true,
        //     margin: -20,
        //     formatter: value => {
        //       return intFormatter(value)
        //     }
        //   },
        // },
      ],
      series: [
        {
          name: 'Total',
          type: 'bar',
          xAxisIndex: 0,
          smooth: false,
          // barWidth: '50%',
          // barMaxWidth: 40,
          nameLocation: 'start',
          emphasis: {
            focus: 'series'
          },
          lineStyle: {
            width: 2,
            opacity: .8
          },
          axisLabel: {
            formatter: function (value) {
              return moneyFormatter(value);
            }
          },
          label: {
            show: data.length < 12,
            position: 'top',
            formatter: (d,i) => {
              return '$' + uniquesFormatter(d.value)
            }
          },
          data: data.map(d => d.budget),
          itemStyle: {
            // borderRadius: [4, 4, 0, 0],
            // color: '#6eb3c4', //colors[5],
            color: '#6eb3c4',
          },
          areaStyle: {
            opacity: 0.3,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: 'rgba(239,188,140,0.29)'
              },
              {
                offset: 0,
                color: 'rgba(196,155,245,0.49)'
              }
            ])
          },
        },
        ...(['tv', 'radio', 'digital', 'social', 'ooh', 'others'].map(platformKey => {
          return {
            name: platformLabelMap[platformKey],
            type: 'bar',
            xAxisIndex: 0,
            smooth: false,
            nameLocation: 'start',
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              width: 2,
              opacity: .8
            },
            axisLabel: {
              formatter: function (value) {
                return moneyFormatter(value);
              }
            },
            label: {
              show: false,
              position: 'top',
              formatter: (d,i) => {
                return moneyFormatter(d.value)
              }
            },
            data: data.map(d => d.breakdown[platformKey]),
            itemStyle: {
              // borderRadius: [4, 4, 0, 0],
              // color: '#6eb3c4', //colors[5],
              // color: '#f1a164',
            },
            areaStyle: {
              opacity: 0.3,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: 'rgba(239,188,140,0.29)'
                },
                {
                  offset: 0,
                  color: 'rgba(196,155,245,0.49)'
                }
              ])
            },
          }
        })),
        // {
        //   name: 'No. of MSQs',
        //   type: 'line',
        //   smooth: false,
        //   yAxisIndex: 1,
        //   barWidth: '50%',
        //   barMaxWidth: 40,
        //   emphasis: {
        //     focus: 'series'
        //   },
        //   data: data.map(d => d.msqCount),
        //   itemStyle: {
        //     color: '#f1a164',
        //     // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        //     //   { offset: 0, color: '#abcefc' },
        //     //   { offset: 1, color: '#8cb9f3' }
        //     // ])
        //   },
        //   areaStyle: {opacity: 0.08},
        // },
        // {
        //   name: 'Onboarded Campaigns',
        //   type: 'line',
        //   smooth: false,
        //   yAxisIndex: 1,
        //   barWidth: '50%',
        //   barMaxWidth: 40,
        //   emphasis: {
        //     focus: 'series'
        //   },
        //   data: data.map(d => d.campaignCount),
        //   itemStyle: {
        //     color: '#c5a8ea',
        //     borderRadius: [6, 6, 6, 6],
        //   },
        //   areaStyle: {opacity: 0.1},
        // },
      ]
    };
    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}


export const ChartWrapper = styled.div`
  width: 100%;
`;
