import React from 'react';
import _ from 'lodash';
import KeyMetric from "./keymetric";
import Breakdown from "./breakdown";
import BreakdownPie from "./breakdownPie";
import Timeseries from "./timeseries";
import TimeseriesStack from "./timeseriesStack";
import RichEditor from "../uikit/editor/index";
import PlatformBreakdown from "./breakdown_platform";
import TVSchedule from "./TVSchedule";
import {metricMap, metricMetaData} from "../utils/metadata";
import {intFormatter} from '../utils/formatter';
import BreakdownExplorer from "./breakdownExplorer";
import BreakdownBarHorizontal from "./breakdownBarHorizontal";
import BreakdownTable from "./breakdownTable";
import CorrelationTable from "./correlationTable";
import ContentEditable from "react-contenteditable";
import keyattribute from "./keyattribute";
import tvProgramSchedule from "./tvProgramSchedule";
import HeatMap from "./heatmap";
import {Aladin_BG, colorCategory4} from "../app/StyleCommon";
import { paginationValues , platformLabelMap} from '../utils/metadata';

export const findPlatformMetrics = platform => {
  let charts = visualisations.filter(d => d.category === 'Key Metrics').filter(d => d.supportPlatforms.indexOf(platform) >= 0);
  return charts.map(d => {
    let key = d.key.substring('keymetric_'.length);
    return {
      key, label: metricMap[key] || key
    };
  }).filter(d => d.key !== 'budget' &&  d.key !== 'pacing' && d.key.indexOf('spots') < 0);
}

const reachMetrics = ['reach', 'reach_pct', 'reach_3_pct'];

const overallMetrics = ['impressions', 'grp_pct', 'reach', 'reach_pct', 'reach_3', 'reach_3_pct', 'spend', 'cpm', 'cpv'];
const overallMetricsWithoutSpend = ['impressions', 'grp_pct', 'reach', 'reach_pct', 'reach_3', 'reach_3_pct'];
const overallMetricsWithoutReachSpend = ['impressions', 'grp_pct'];
const overallMetricsQuant = ['impressions', 'grp_pct', 'reach', 'reach_pct','reach_3', 'reach_3_pct', 'spend'];
const overallMetricsWithoutSpendQuant = ['impressions', 'grp_pct', 'reach', 'reach_pct','reach_3', 'reach_3_pct', 'spend'];
const overallMetricsPerf = [ 'cpm', 'cpv'];
const overallMetricsWithoutSpendPerf = [ 'cpm', 'cpv'];

const tvMetrics = ['spots', 'impressions_per_spots', 'reach_3', 'reach_3_pct',];
const tvMetricsWithoutReach = ['spots', 'impressions_per_spots'];
const tvMetricsWithSpend = ['spots', 'impressions_per_spots', 'spend', 'cpm'];
const tvMetricsQuant = ['spots', 'reach_3', 'reach_3_pct', ];
const tvMetricsQuantWithSpend = ['spots', 'spend', 'reach_3', 'reach_3_pct', ];
const tvMetricsPerf = ['impressions_per_spots'];
const tvMetricsPerfWithSpend = ['impressions_per_spots', 'cpm', 'cpv'];

const radioMetrics = ['spots', 'impressions_per_spots', 'reach_3', 'reach_3_pct', ];
const radioMetricsWithoutReach = ['spots', 'impressions_per_spots'];
const radioMetricsWithSpend = ['spots', 'impressions_per_spots', 'spend', 'cpm'];
const radioMetricsQuantWithSpend = ['spots', 'spend', 'reach_3', 'reach_3_pct', ];

const digitalMoatMetrics = ['impressions_analyzed', 'valid_and_viewable', 'in_view_percent'];
const digitalPixelMetrics = ['view_through_conversions', 'click_through_conversions', 'advertiser_view_through_sales_sgd', 'advertiser_click_through_sales_sgd'];
export const digitalMetrics = ['display_impressions', 'video_impressions', 'reach_3', 'reach_3_pct', 'clicks', 'display_clicks', 'video_clicks', 'ctr', 'display_ctr', 'video_ctr', 'cpc', 'video_starts', 'video_completions', 'video_completions_25pct', 'video_completions_50pct', 'video_completions_75pct', 'vcr', 'vcr_25pct', 'vcr_50pct', 'vcr_75pct', 'pv', ...digitalMoatMetrics, ...digitalPixelMetrics]
const digitalMetricsWithoutSpend = ['display_impressions', 'video_impressions','reach_3', 'reach_3_pct', 'clicks', 'display_clicks', 'video_clicks', 'ctr', 'video_starts', 'video_completions', 'video_completions_25pct', 'video_completions_50pct', 'video_completions_75pct', 'vcr', 'vcr_25pct', 'vcr_50pct', 'vcr_75pct', ...digitalMoatMetrics, ...digitalPixelMetrics]
const digitalMetricsWithoutSpendQuant = ['display_impressions', 'video_impressions','reach_3', 'reach_3_pct', 'clicks', 'display_clicks', 'video_clicks','video_starts', 'video_completions', 'video_completions_25pct', 'video_completions_50pct', 'video_completions_75pct', 'pv', ...digitalMoatMetrics, ...digitalPixelMetrics]


const socialMetrics = ['likes', 'comments', 'engagement', 'cpm', 'cpc', 'cpe', 'engagement_rate', 'engagement_by_reach'];
const socialMetricsWithoutSpend = ['likes', 'comments', 'engagement', 'engagement_rate', 'engagement_by_reach'];
const socialMetricsWithoutSpendQuant = ['likes', 'comments', 'engagement'];

const tvDimensions = ['channel', 'daypart', 'timebelt', 'program'];
const tvDimensionsShort = ['channel', 'daypart', 'timebelt'];

const radioDimensions = ['station', 'daypart', 'timebelt'];
const radioDimensionsShort = ['station', 'daypart', 'timebelt'];

const digitalDimensions = ['sites', 'buy_model', 'creative', 'ad_format', 'ad_type', 'access_method', 'lineitem', 'meid_seg'];
const digitalDimensionsShort = ['sites', 'buy_model', 'ad_format', 'ad_type', 'access_method'];

const socialDimensions = ['influencer', 'influencer_size', 'social_platform', 'category', 'post', 'handler', 'agency', 'location', 'content_type'];
const socialDimensionsShort = ['influencer', 'influencer_size', 'social_platform', 'category'];

export const visualisations = [
  ...(
    _.uniq(['budget', 'pacing', ...overallMetrics, ...tvMetrics, ...digitalMetrics, ...radioMetrics, ...socialMetrics]).map(metric => {
      let metricMeta = metricMetaData.find(d => d.key === metric) || {};
      return {
        hidden: true,
        key: `keymetric_${metric}`,
        label: metricMap[metric],
        category: 'Key Metrics',
        Component: KeyMetric,
        cfg: {metric: metric, size: 'size1_5'},
        supportClientData: true,
        demoData: {value: metricMeta.eg || 10000},
        detailOptions: ['show', 'hide'],
        metricOptions: ['budget', 'pacing', ...overallMetrics],
        metricOptionsPlatformLevel: {tv: tvMetrics, digital: digitalMetrics, radio: radioMetrics},
        supportPlatforms: metricMeta.supportPlatforms || ['overall', 'digital', 'tv', 'radio'],
        searchKeywords: [metric, metricMap[metric]].map(s => s.toLowerCase()).join(' ')
      };
    })
  ),
  // {
  //   key: 'campaign_attribute',
  //   label: 'Campaign Attribute',
  //   category: 'Key Metrics',
  //   Component: keyattribute,
  //   visSize: 'size1_4',
  //   demoData: {
  //     key: 'advertiser',
  //     value: 'KFC'
  //   },
  //   cfg: {field: 'advertiser', size: 'size1_4'},
  //   metricOptionField: 'field',
  //   metricOptions: ['name', 'start_date', 'end_date', 'advertiser', 'brands', 'industry'],
  //   metricOptionsLabel: ['Name', 'Start Date', 'End Date', 'Advertiser', 'Brands', 'Industry'],
  //   metricOptionsPlatformLevel: {overall: [] , tv: [], digital: []},
  //   allowMultiple: true,
  //   supportPlatforms: ['overall'],
  // },
  {
    key: 'timeseries',
    showLabel: true,
    label: 'Time Series Chart',
    supportClientData: true,
    labelFunc: (d, context) => {
      let isCumulative = reachMetrics.indexOf(d.cfg.metric) >= 0 ||  d.cfg.cumulative
      let secondMetricMeta = context.mixedMetaData.find(m => m.key === d.cfg.metric2);
      if(d.cfg.metric2 === d.cfg.metric) {
        secondMetricMeta = null;
      }
      const metricLabel = context.mixedLabelMap[d.cfg.metric] || (d.cfg.metric || '').replace('client_', '');
      return `${_.capitalize(d.cfg.unit)} ${isCumulative? 'Cumulative ': ' '}${metricLabel} ${secondMetricMeta ? (' / ' + secondMetricMeta.label) : ''}`
    },
    category: 'Charts',
    Component: Timeseries,
    thumbnail: '/img/vis/timeseries.png',
    noMixedBenchmark: true,
    dateUnitOptions: ['daily', 'weekly', 'monthly'],
    chartTypeOptions: ['bar', 'line'],
    chartTypeOptionsDefault: 'bar',
    chartTypeOptionsLabel: 'Primary Metric Visualisation Type',
    metricOptions: [...overallMetricsWithoutSpend],
    metricOptionsLabel: 'Primary Metric (Left Y-Axis)',
    metricOptionCumulativeOption: true,
    metricOptionsPlatformLevel: {overall: overallMetricsPerf, tv: tvMetricsWithSpend, digital: digitalMetricsWithoutSpend, social: socialMetricsWithoutSpend, radio: radioMetricsWithSpend},
    secondaryMetricOptionsLabel: 'Secondary Metric (Right Y-Axis)',
    secondaryMetricOptions: [],
    secondaryMetricOptionsPlatformLevel: {overall: overallMetricsPerf, tv: tvMetricsWithSpend, digital: digitalMetricsWithoutSpend, social: socialMetricsWithoutSpend, radio: radioMetricsWithSpend},
    secondaryMetricOptionField: 'metric2',
    secondaryChartTypeOptionsLabel: 'Secondary Metric Visualisation Type  ',
    secondaryChartTypeOptions: ['line', 'bar'],
    secondaryChartTypeOptionsDefault: 'line',
    cfg: {metric: 'impressions', dimension: 'date', unit: 'daily', type: 'bar', size: 'size1_2'},
    supportPlatforms: ['overall', 'tv', 'digital', 'social', 'radio'],
    searchKeywords: ['Time series'].map(s => s.toLowerCase()).join(' '),
    hideClientMetricifNoClientDimension: false
  },
  {
    key: 'timeseries_stack',
    supportClientData: true,
    showLabel: true,
    label: `Time Series Stacked Chart`,
    labelFunc: (d, context) => {
      let isCumulative = reachMetrics.indexOf(d.cfg.metric) >= 0 ||  d.cfg.cumulative
      let secondMetricMeta = context.mixedMetaData.find(m => m.key === d.cfg.metric2);
      const metricLabel = context.mixedLabelMap[d.cfg.metric] || (d.cfg.metric || '').replace('client_', '');
      return `${_.capitalize(d.cfg.unit)} ${isCumulative? 'Cumulative ': ' '}${metricLabel} ${secondMetricMeta ? (' / ' + secondMetricMeta.label) : ''} by ${context.mixedDimensionsLabelMap[d.cfg.dimension]}`
    },
    category: 'Charts',
    Component: TimeseriesStack,
    thumbnail: '/img/vis/timeseries_stack.png',
    noMixedBenchmark: true,
    dateUnitOptions: ['daily', 'weekly', 'monthly'],
    metricOptions: [...overallMetricsWithoutSpend],
    chartTypeOptions: ['bar', 'line'],
    chartTypeOptionsDefault: 'bar',
    metricOptionsLabel: 'Metric',
    metricOptionCumulativeOption: true,
    metricOptionsPlatformLevel: {overall: overallMetrics, tv: [...tvMetricsWithSpend], digital: digitalMetricsWithoutSpend, social: socialMetricsWithoutSpend, radio: radioMetricsWithSpend},
    dimensionOptions: [],
    dimensionOptionsPlatformLevel: {tv: tvDimensionsShort, digital: digitalDimensionsShort, social: socialDimensionsShort, radio: radioDimensionsShort},
    dimensionOptionField: 'dimension',
    cfg: {unit: 'daily', metric: 'impressions', dimension: 'access_method', size: 'size100'},
    defaultByPlatformLevel: {
      tv: {
        cfg: {unit: 'daily', metric: 'impressions', dimension: 'daypart', size: 'size100'}
      },
      digital: {
        cfg: {unit: 'daily', metric: 'impressions', dimension: 'sites', size: 'size100'}
      },
      social: {
        cfg: {unit: 'daily', metric: 'impressions', dimension: 'influencer', size: 'size100'}
      },
      radio: {
        cfg: {unit: 'daily', metric: 'impressions', dimension: 'daypart', size: 'size100'}
      }
    },
    supportPlatforms: ['tv', 'digital', 'social', 'radio'],
    searchKeywords: ['Time series Stacked'].map(s => s.toLowerCase()).join(' '),
    hideClientMetricifNoClientDimension: true
  },
  {
    key: 'breakdown_platform_pie',
    supportClientData: true,
    showLabel: true,
    label: 'Metric by Platform',
    labelFunc: (d, context) => {
      const metricLabel = context.mixedLabelMap[d.cfg.metric] || (d.cfg.metric || '').replace('client_', '');
      return`${metricLabel} by ${context.mixedDimensionsLabelMap[d.cfg.dimension || 'platform']}`
    },
    category: 'Charts',
    Component: BreakdownPie,
    thumbnail: '/img/vis/platform_pie.png',
    demoData: [{key: 'tv', value: 10000}, {key: 'digital', value: 3000} , {key: 'social', value: 100}],
    cfg: {metric: 'impressions', dimension: 'platform', size: 'size1_3'},
    dimensionOptions: [],
    dimensionOptionsWithClient: ['platform'],
    noMixedBenchmark: true,
    metricOptions: ['budget', ...overallMetricsQuant],
    supportPlatforms: ['overall'],
    searchKeywords: ['Metric by Platform', 'pie'].map(s => s.toLowerCase()).join(' '),
    hideClientMetricifNoClientDimension: true
  },
  {
    key: 'breakdown_platform',
    showLabel: true,
    supportClientData: true,
    label: 'Metric by Platform',
    labelFunc: (d, context) => {
      const metricLabel = context.mixedLabelMap[d.cfg.metric] || (d.cfg.metric || '').replace('client_', '');
      return `${metricLabel} by ${context.mixedDimensionsLabelMap[d.cfg.dimension || 'platform']}`
    },
    category: 'Charts',
    Component: PlatformBreakdown,
    thumbnail: '/img/vis/platform_breakdown_bar.png',
    demoData: [
      {key: 'tv', actual: 10000, target: 20000},
      {key: 'digital', actual: 3500, target: 3000} ,
      {key: 'social', actual: 200, target: 100}
    ],
    cfg: {metric: 'impressions',dimension: 'platform', size: 'size1_3'},
    noMixedBenchmark: true,
    metricOptions: overallMetrics,
    dimensionOptions: [],
    dimensionOptionsWithClient: ['platform'],
    supportPlatforms: ['overall'],
    searchKeywords: ['Metric by Platform', 'bar'].map(s => s.toLowerCase()).join(' '),
    hideClientMetricifNoClientDimension: true
  },
  {
    key: 'breakdown_bar_horizontal', // horizontal means item layout vertically
    showLabel: true,
    supportClientData: true,
    label: 'Vertical Bar Chart',
    labelFunc: (d, context) => {
      return `${_.uniq([d.cfg.metric, ...(d.cfg.metrics || [])]).map(m => context.mixedLabelMap[m]).join(' / ')} by ${context.mixedDimensionsLabelMap[d.cfg.dimension]}`
    },
    searchKeywords: ['Vertical Bar Chart', 'bar'].map(s => s.toLowerCase()).join(' '),
    category: 'Charts',
    Component: BreakdownBarHorizontal,
    thumbnail: '/img/vis/breakdown_bar_horizontal.png',
    cfg: {metric: 'impressions', dimension: 'sites', metrics: ['ctr'], size: 'size1_2'},
    defaultByPlatformLevel: {
      tv: {
        cfg: {metric: 'impressions', dimension: 'channel', metrics: ['cpm'], size: 'size1_3'},
      },
      social: {
        cfg: {metric: 'impressions', dimension: 'influencer', metrics: ['cpm'], size: 'size1_3'},
      },
      radio: {
        cfg: {metric: 'impressions', dimension: 'station', metrics: ['cpm'], size: 'size1_3'},
      }
    },
    dimensionOptions: [],
    dimensionOptionsPlatformLevel: {tv: tvDimensions, digital: [...digitalDimensions, 'age', 'gender', 'interest'], social: socialDimensions},
    dimensionOptionField: 'dimension',
    chartTypeOptions: ['line', 'bar'],
    chartTypeOptionsDefault: 'bar',
    chartTypeOptionsLabel: 'Primary Metric Visualisation Type',
    metricOptions: overallMetricsWithoutSpend,
    metricOptionsPlatformLevel: {tv: tvMetricsWithSpend, digital: digitalMetricsWithoutSpend, social: socialMetricsWithoutSpend, radio: radioMetricsWithSpend},
    metricOptionsLabel: 'Primary Metric (Left Y-Axis)',
    secondaryChartTypeOptions: ['line', 'bar'],
    secondaryChartTypeOptionsDefault: 'line',
    secondaryChartTypeOptionsLabel: 'Secondary Metric Visualisation Type  ',
    secondaryMetricOptions: overallMetricsWithoutSpend,
    secondaryMetricOptionsPlatformLevel: {tv: tvMetricsWithSpend, digital: digitalMetricsWithoutSpend, social: socialMetricsWithoutSpend, radio: radioMetricsWithSpend},
    secondaryMetricOptionField: 'metrics',
    secondaryMetricOptionsLabel: 'Secondary Metric (Right Y-Axis)',
    secondaryMetricOptionMutuallyExclusive: (metric1, metric2) => {
      return !([
        ['video_starts', 'video_completions', 'video_completions_25pct', 'video_completions_50pct', 'video_completions_75pct'],
        ['ctr', 'vcr', 'vcr_25pct', 'vcr_50pct', 'vcr_75pct'],
        ['cpc', 'cpm'],
      ].find(group => group.indexOf(metric1) >= 0 && group.indexOf(metric2) >= 0));
    },
    secondaryMetricMulti: true,
    supportPlatforms: ['digital', 'tv', 'social', 'radio'],
    hideClientMetricifNoClientDimension: true
  },
  {
    key: 'breakdown_vertical_bar', // vertical means item layout vertically
    showLabel: true,
    supportClientData: true,
    label: 'Horizontal Bar Chart',
    labelFunc: (d, context) => {
      const metricLabel = context.mixedLabelMap[d.cfg.metric] || (d.cfg.metric || '').replace('client_', '');
      return `${metricLabel} by ${context.mixedDimensionsLabelMap[d.cfg.dimension]}`
    },
    searchKeywords: ['Horizontal Bar Chart', 'bar'].map(s => s.toLowerCase()).join(' '),
    category: 'Charts',
    Component: Breakdown,
    thumbnail: '/img/vis/breakdown_bar_vertical.png',
    demoData: [{key: 'Mobile App', value: 4000}, {key: 'Mobile Web', value: 3000} , {key: 'Fixed Web', value: 1200}],
    cfg: {
      metric: 'impressions',
      dimension: 'access_method',
      size: 'size1_2'
    },
    defaultByPlatformLevel: {
      tv: {
        cfg: {metric: 'impressions', dimension: 'channel', size: 'size1_3'},
      },
      social: {
        cfg: {metric: 'impressions', dimension: 'influencer', size: 'size1_3'},
      },
      radio: {
        cfg: {metric: 'impressions', dimension: 'station', size: 'size1_3'},
      }
    },
    dimensionOptions: [],
    dimensionOptionsPlatformLevel: {tv: tvDimensions, digital: [...digitalDimensions, 'age', 'gender', 'interest'], social: socialDimensions, radio: radioDimensions},
    dimensionOptionField: 'dimension',
    metricOptions: overallMetricsWithoutSpend,
    metricOptionsPlatformLevel: {tv: tvMetricsWithSpend, digital: digitalMetricsWithoutSpend, social: socialMetricsWithoutSpend, radio: radioMetricsWithSpend},
    supportPlatforms: ['digital', 'tv', 'social', 'radio'],
    hideClientMetricifNoClientDimension: true
  },
  {
    key: 'breakdown_pie',
    showLabel: true,
    supportClientData: true,
    label: 'Pie Chart',
    labelFunc: (d, context) => {
      const metricLabel = context.mixedLabelMap[d.cfg.metric] || (d.cfg.metric || '').replace('client_', '');
      return `${metricLabel} by ${context.mixedDimensionsLabelMap[d.cfg.dimension]}`
    },
    searchKeywords: ['Pie Chart', 'pie'].map(s => s.toLowerCase()).join(' '),
    category: 'Charts',
    Component: BreakdownPie,
    thumbnail: '/img/vis/breakdown_pie.png',
    cfg: {metric: 'impressions', dimension: 'sites', size: 'size1_3'},
    defaultByPlatformLevel: {
      tv: {
        cfg: {metric: 'impressions', dimension: 'channel', size: 'size1_3'},
      },
      radio: {
        cfg: {metric: 'impressions', dimension: 'station', size: 'size1_3'},
      },
      social: {
        cfg: {metric: 'impressions', dimension: 'influencer', size: 'size1_3'},
      }
    },
    dimensionOptions: [],
    dimensionOptionsPlatformLevel: {tv: tvDimensions, digital: [...digitalDimensions, 'age', 'gender', 'interest'], social: socialDimensions, radio: radioDimensions},
    dimensionOptionField: 'dimension',
    metricOptions: overallMetricsWithoutSpendQuant,
    metricOptionsPlatformLevel: {tv: tvMetricsQuantWithSpend, digital: digitalMetricsWithoutSpendQuant, social: socialMetricsWithoutSpendQuant, radio: radioMetricsQuantWithSpend},
    pageSizeOptions: ['top 10', 'all'],
    pageSizeOptionsLabel: 'Max count of items showing in chart.',
    supportPlatforms: ['digital', 'tv', 'social', 'radio'],
    hideClientMetricifNoClientDimension: true
  },
  {
    key: 'dimension_breakdown_table',
    showLabel: true,
    supportClientData: true,
    label: `Table`,
    labelFunc: (d, context) => {
      if(d.cfg.dimension) {
        return `${context.mixedDimensionsLabelMap[d.cfg.dimension]}`
      } else if (d.cfg.dimensions){
        return d.cfg.dimensions.map(dim => context.mixedDimensionsLabelMap[dim]).join(', ');
      } else {
        return 'Table Chart';
      }


    },
    searchKeywords: ['Table', 'breakdown'].map(s => s.toLowerCase()).join(' '),
    category: 'Charts',
    Component: BreakdownTable,
    thumbnail: '/img/vis/digital_site_table.png',
    //in phase 2 we start to support multiple dimensions
    cfg: {metric: 'impressions', dimensions: ["sites"], metrics: ['impressions', 'reach', 'clicks'], size: 'size1_2'},
    defaultByPlatformLevel: {
      tv: {
        cfg: {metrics: ['spots', 'impressions', 'cpm', 'impressions_per_spots'], dimensions: ['channel'],  size: 'size1_2'},
      },
      radio: {
        cfg: {metrics: ['spots', 'impressions','reach', 'cpm', 'cpv'], dimensions: ['station'],  size: 'size1_2'},
      },
      social: {
        cfg: {metrics: socialMetricsWithoutSpend, dimensions: ['influencer'],  size: 'size1_2'},
      }
    },
    dimensionOptions: [],
    dimensionOptionsPlatformLevel: {tv: tvDimensions, digital: [...digitalDimensions, 'age', 'gender', 'interest'], social: socialDimensions, radio: radioDimensions},
    dimensionOptionField: 'dimensions',
    dimensionOptionsLabel: 'Dimensions',
    metricOptions: overallMetricsWithoutSpend,
    metricOptionsSortable: true,
    metricOptionsPlatformLevel: {tv: tvMetricsWithSpend, digital: digitalMetricsWithoutSpend, social: socialMetricsWithoutSpend, radio: radioMetricsWithSpend},
    metricOptionField: 'metrics',
    paginationTypeOptions: ['pagination', 'scroll', 'all'],
    pageSizeOptions: paginationValues,
    supportPlatforms: ['digital', 'tv', 'social', 'radio'],
    hideClientMetricifNoClientDimension: true
  },
  {
    key: 'two_level_breakdown',
    showLabel: true,
    label: 'Drilldown Explorer',
    searchKeywords: ['Drilldown Explorer'].map(s => s.toLowerCase()).join(' '),
    category: 'Charts',
    Component: BreakdownExplorer,
    thumbnail: '/img/vis/dimension_explorer.png',
    cfg: {
      dimension: 'creative',
      metric: 'impressions',
      metrics: ['impressions', 'clicks', 'ctr'],
      dimension2: 'sites',
      size: 'size100'
    },
    defaultByPlatformLevel: {
      tv: {
        cfg: {
          dimension: 'channel',
          metric: 'impressions',
          metrics: ['impressions', 'spots', 'cpm'],
          dimension2: 'program',
          size: 'size1_2'
        },
      },
      radio: {
        cfg: {
          dimension: 'station',
          metric: 'impressions',
          metrics: ['impressions', 'spots', 'cpm'],
          dimension2: 'daypart',
          size: 'size1_2'
        },
      },
    },
    // chartTypeOptions: ['pie', 'bar'],
    // chartTypeOptionsLabel: 'Main Chart Type',
    dimensionOptions: [],
    dimensionOptionsPlatformLevel: {tv: tvDimensions, digital: digitalDimensions, radio: radioDimensions},
    dimensionOptionField: 'dimension',
    metricOptions: overallMetricsWithoutSpendQuant,
    metricOptionsPlatformLevel: {tv: tvMetricsQuantWithSpend, digital: digitalMetricsWithoutSpendQuant, radio: radioMetricsQuantWithSpend},
    secondaryMetricOptions: overallMetricsWithoutSpend,
    secondaryMetricOptionsPlatformLevel: {tv: tvMetricsWithSpend, digital: digitalMetricsWithoutSpend, radio: radioMetricsWithSpend},
    secondaryMetricOptionField: 'metrics',
    secondaryMetricMulti: true,
    secondaryDimensionOptions: [],
    secondaryDimensionOptionsPlatformLevel: {tv: tvDimensions, digital: digitalDimensions, radio: radioDimensions},
    secondaryDimensionOptionField: 'dimension2',
    supportPlatforms: ['tv', 'digital', 'radio'],
  },
  {
    key: 'correlation_table',
    showLabel: true,
    supportClientData: true,
    supportClientDataExceptions: ['metricOptions'],
    label: 'Correlation',
    labelFunc: (d, context) => {
      const metricLabel = context.mixedLabelMap[d.cfg.metric] || (d.cfg.metric || '').replace('client_', '');
      const metricLabel2 = context.mixedLabelMap[d.cfg.metric2] || (d.cfg.metric2 || '').replace('client_', '');
      return `Correlation of ${metricLabel} vs ${metricLabel2}`
    },
    category: 'Charts',
    Component: CorrelationTable,
    thumbnail: '/img/vis/correlation_table.png',
    noMixedBenchmark: true,
    metricOptions: [...overallMetricsWithoutReachSpend],
    metricOptionsLabel: 'Primary Metric',
    metricOptionsPlatformLevel: {overall: overallMetrics, tv: tvMetricsWithoutReach, digital: digitalMetrics, social: socialMetrics, radio: radioMetrics},
    secondaryMetricOptionsLabel: 'Secondary Metric',
    secondaryMetricOptions: [],
    secondaryMetricOptionsPlatformLevel: {overall: overallMetrics, tv: tvMetricsWithoutReach, digital: digitalMetrics, social: socialMetrics, radio: radioMetrics},
    secondaryMetricOptionField: 'metric2',
    cfg: {metric: 'impressions', metric2: 'spots', size: 'size1_2'},
    defaultByPlatformLevel: {
      digital: {
        cfg: {
          metric: 'impressions',
          metric2: 'clicks',
        },
      },
    },
    supportPlatforms: ['tv', 'digital', 'radio'],
    searchKeywords: ['Correlation'].map(s => s.toLowerCase()).join(' '),
    hideClientMetricifNoClientDimension: false
  },
  {
    key: 'heatmap',
    showLabel: true,
    label: 'Spots Heat Map',
    searchKeywords: ['Heat map'].map(s => s.toLowerCase()).join(' '),
    category: 'Charts',
    Component: HeatMap,
    thumbnail: '/img/vis/heatmap.png',
    demoData: {
      data: [
        {weekday: 'monday', hour: '00', spots: 10},
        {weekday: 'monday', hour: '01', spots: 8},
      ]
    },
    cfg: {
      metric: 'spots',
      size: 'size100'
    },
    defaultByPlatformLevel: {
      tv: {
        cfg: {
          metric: 'spots',
        }
      },
      radio: {
        cfg: {
          metric: 'spots',
        }
      },
      digital: {
        cfg: {
          dimensions: ['date', 'timebelt'],
          metric: 'impressions',
        }
      }
    },
    metricOptions: ['spots'],
    // metricOptionsPlatformLevel: {tv: tvMetrics, digital: digitalMetrics},
    supportPlatforms: ['tv', 'radio'],
  },

  {
    key: 'tv_schedule',
    showLabel: true,
    label: 'Flight Schedule',
    searchKeywords: ['TV Flight Schedule'].map(s => s.toLowerCase()).join(' '),
    category: 'Charts',
    Component: TVSchedule,
    thumbnail: '/img/vis/tv_schedule.png',
    demoData: {
      period: {start: '2023-02-07', end: '2023-02-07'},
      data: [
        {date: '2023-01-27', pt: 1, opt: 1, channel8: 3, cna: 3, impressions: 100,},
        {date: '2023-01-28', pt: 1, opt: 1, channel8: 3, cna: 3, impressions: 100,},
        {date: '2023-01-29', pt: 1, opt: 1, channel8: 3, cna: 3, impressions: 100,},
        {date: '2023-01-30', pt: 1, opt: 1, channel8: 3, cna: 3, impressions: 100,},
        {date: '2023-01-31', pt: 1, opt: 1, channel8: 3, cna: 3, impressions: 100,},
      ]
    },
    cfg: {dimension: 'date', metrics: ['spots', 'pt', 'opt', 'budget'], size: 'size100'},
    // metricOptions: ['budget', 'impressions', 'reach', 'cpm'],
    // metricOptionsPlatformLevel: {tv: ['spots', 'pt', 'opt'], digital: ['clicks', 'ctr', 'video_starts', 'video_completions', 'vcr']},
    paginationTypeOptions: ['column1', 'column2', 'column3', 'column4'],
    menuLabelMap: {'column1': '1 Column', column2: '2 Columns', column3: '3 Columns', column4: '4 Columns'},
    paginationTypeOptionsLabel: 'Column Style',
    supportPlatforms: ['tv', 'radio'],
  },
  {
    key: 'tv_program_lookup',
    showLabel: true,
    label: 'TV Program Lookup',
    searchKeywords: ['tv program lookup'].map(s => s.toLowerCase()).join(' '),
    category: 'Charts',
    Component: tvProgramSchedule,
    thumbnail: '/img/vis/tv_program_lookup.png',
    demoData: {
      period: {start: '2023-02-07', end: '2023-02-07'},
      data: [
        {date: '2023-01-27', pt: 1, opt: 1, channel8: 3, cna: 3, impressions: 100,},
        {date: '2023-01-28', pt: 1, opt: 1, channel8: 3, cna: 3, impressions: 100,},
        {date: '2023-01-29', pt: 1, opt: 1, channel8: 3, cna: 3, impressions: 100,},
        {date: '2023-01-30', pt: 1, opt: 1, channel8: 3, cna: 3, impressions: 100,},
        {date: '2023-01-31', pt: 1, opt: 1, channel8: 3, cna: 3, impressions: 100,},
      ]
    },
    cfg: {size: 'size100'},
    supportPlatforms: ['tv'],
  },
  {
    key: 'campaign_information',
    label: 'Campaign Summary',
    searchKeywords: ['Campaign Summary'].map(s => s.toLowerCase()).join(' '),
    category: 'Others',
    Component: (props) => <div style={{background: props.cfg.bg}}><RichEditor {...props}/></div>,
    thumbnail: '/img/vis/campaign_info.png',
    visSize: 'size100',
    demoData: `
    <p><strong>[DOTR-3440] JDE SuperCoffee Sep2021</strong></p>
    <hr/>
    <p>Burst 1: 29 Sep - 23 Nov</p>
    <p>Campaign Type: Guaranteed impressions</p>
    <p>Objective: Deliver 311,111 impressions during campaign period</p>
    `,
    cfg: {size: 'size100'},
    bgColorOptions: ['white', Aladin_BG, ...colorCategory4.slice(0, 13)],
    allowMultiple: true,
    simpleComponent: true,
    supportPlatforms: ['common', 'overall', 'digital', 'tv', 'social'],
  },
  {
    key: 'editor',
    label: 'Free text',
    searchKeywords: ['Free text', 'editor'].map(s => s.toLowerCase()).join(' '),
    category: 'Others',
    Component: (props) =>
      <div style={{background: props.cfg.bg}}>
      <RichEditor {...props}/>
    </div>,
    visSize: 'size100',
    demoData: '<ol><li>Type here</li></ol>',
    cfg: {size: 'size100'},
    bgColorOptions: ['white', Aladin_BG, ...colorCategory4.slice(0, 13)],
    allowMultiple: true,
    simpleComponent: true,
    supportPlatforms: ['common', 'overall', 'tv', 'digital', 'social'],
  },
  {
    key: 'seperator',
    hidden: true,
    label: 'Separator',
    searchKeywords: ['Separator'].map(s => s.toLowerCase()).join(' '),
    category: 'Others',
    Component: (props) => {
      return (
        <div style={{ padding: '5px 20px 5px 30px', minHeight: '15px', textAlign: 'center', background: props.cfg.bg}}>
          <ContentEditable
            html={props.cfg.data || props.data || <span></span>} // innerHTML of the editable div
            onChange={e => {
              props.cfg.data = e.target.value;
              props.chartStateChanged({data: e.target.value || '&nbsp;'}, true);
              // props.onChange(e.target.value);
            }} // handle innerHTML change
            style={{minWidth: '20px'}}
            tagName='span' // Use a custom HTML tag (uses a div by default)
          />
        </div>
      )
    },
    hideConfig: false,
    visSize: 'size100',
    demoData: 'Separator',
    cfg: {size: 'size100'},
    bgColorOptions: ['white', Aladin_BG, ...colorCategory4.slice(0, 13)],
    allowMultiple: true,
    simpleComponent: true,
    supportPlatforms: ['common', 'overall', 'tv', 'digital', 'social'],
  }
]

export const visualisationsForReach = _.cloneDeep(visualisations).map(d => {
  if((d.key.startsWith('breakdown_') || d.key.startsWith('timeseries')) && d.cfg.metric === 'impressions') {
    d.cfg.metric = 'reach';
  }
  return d;
})
