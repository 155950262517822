import React from 'react';
import ReactDOM from "react-dom";
import {createRoot} from "react-dom/client";
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { htmlToText } from 'html-to-text';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {Wrapper, SaveReportDailogWrapper} from './builder.style';
import {StyledDropdown} from "../uikit/dropdown/styled";
import {Btn, CondirmDialog, ProgressBar} from "../uikit";
import ContentEditable from 'react-contenteditable'
import {visualisations} from "../charts/index";
import {Link} from "react-router-dom";
import {post} from "../utils/request";
import Table from "../uikit/table";
import {saveAs} from 'file-saver';
import qs from "qs";
import DndMixin from "../charts/dndMixin";
import ShareSettings from "./share";
import Datasource from "./datasource";
import Template from "./template";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {generateDashboardContext} from "../charts/dndMixin.client";
import {AlertBox} from "../campaigns/build.style";

export default withRouter(withConsumer(class extends DndMixin {

  constructor(props) {
    super(props);
    this.state = {
      autoSave: false,
      tabIndex: 0,
      mode: 'customize',
      report: null,
      dashboard: null,
      loadingMap: {},
      visPanelClose: false,
      platformKeys: ['overall'],
      touched: false,
    }
    if (props.location.state) {
      const {datasource, dashboard, fr, template} = props.location.state;
      if(dashboard) {
        this.state.touched = true;
        this.state.template = template;
        this.state.report = _.cloneDeep(dashboard);
        this.state.dashboard = {overall: this.state.report};
        this.state.report.datasource = datasource;
        this.state.report.template_id = template.id;
      }
    }
  }

  buildQuery() {
    const {report} = this.state;
    const urlParams = qs.parse(window.location.search.slice(1));
    return {id: urlParams.id, report};
  }

  componentDidMount() {
    super.componentDidMount();
    const {report} = this.state;
    if(report && !report.datasource) {
      this.showDatasourceBox();
    }
  }

  getUrlParams() {
    const urlParams = qs.parse(window.location.search.slice(1));
    return urlParams || {};
  }

  render() {
    const {data, error, loading} = this.state;
    const {report, template, dashboard, alerts, showDrop, visPanelClose, touched, historyStack = []} = this.state;
    const visualisationGroups = _.groupBy(visualisations, _.property('category'));
    const {campaigns = [], benchmark_campaigns = [], metaMap = {}} = (report || {}).datasource || {};
    const campaign = campaigns[0];
    const benchmark = benchmark_campaigns[0];
    const fromDetailPage = campaign && this.getUrlParams().fr === 'campaign_dashboard';

    const {session = {}} = this.props.appState || {};
    let canBuildReport = session.rights.indexOf('Build Report') >= 0;
    let canViewReport = session.rights.indexOf('View Report') >= 0;
    let canShareReport = session.rights.indexOf('Share Report') >= 0;
    let canDeleteReport = session.rights.indexOf('Delete Report') >= 0;
    let canEditTemplate = session.rights.indexOf('Create Report Template') >= 0;
    let canDownloadReport = session.rights.indexOf('Download Report') >= 0;
    let canSetupCampaign = session.rights.indexOf('Setup/Update Campaign') >= 0;
    return (
      <MainLayout activeItem="reporting" breadcrumb={_.compact([
        !fromDetailPage ? {path: '/reporting', label: 'Reporting'} : null,
        fromDetailPage ? {path: '/campaigns', label: 'Campaigns'} : null,
        fromDetailPage ? {path: `/campaign/details?id=${campaign.id}`, label: 'Campaign Dashboard'} : null,
        {path: '/reporting/builder', label: canBuildReport ? 'Report Builder' : 'View Report'}
      ])} loading={loading}>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/reportBuilderQuery`}
            params={this.buildQuery()}
            successHandler={data => {
              this.setState({
                data: data,
                context: generateDashboardContext(data)
              });

              if(data.report) {
                const {campaigns = []} = data.report.datasource || {};
                const platformKeys = (campaigns[0] || {}).platform_keys || [];
                this.setState({
                  report: data.report,
                  reportData: data.reportData,
                  alerts: data.alerts,
                  digitalOptionMetadata: data.digitalOptionMetadata,
                  dashboard: {overall: data.report},
                  alwaysShowSectionFilters: platformKeys.length === 1 && platformKeys[0] === 'digital'
                });
              }
              if(data.template) {
                this.setState({template: data.template});
              }
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!error && canBuildReport && <ErrorMessage message={error}/>
        }
        {
          (!canBuildReport || (data && data.notAllowed)) && <ErrorMessage message={'Not Allowed'}/>
        }
        {
          !!report && canBuildReport &&
          <Wrapper>
            <div className="page-header">
              <strong className="title">
                <ContentEditable
                  html={report.name || 'Untitled'} // innerHTML of the editable div
                  onChange={e => {
                    this.reportAttrs = this.reportAttrs || {name: report.name};
                    this.saveHistoryDebounced();
                    report.name = htmlToText(e.target.value);
                    this.setState({report, touched: true}, () => this.saveReportSilent());
                  }} // handle innerHTML change
                  tagName='span' // Use a custom HTML tag (uses a div by default)
                />
                <p>
                  <div className="campaign-info">
                    {
                      !!campaign &&
                      <span>
                        Campaign:
                        <a href={`/campaign/details?id=${campaign.id}`}  target="_blank">
                          {campaign.name} ({campaign.id})
                        </a>
                      </span>

                    }
                    {
                      !!benchmark &&
                      <span>
                        Benchmark with:
                      <a href={`/campaign/details?id=${benchmark.id}`} target="_blank">
                        {benchmark.name} ({benchmark.id})
                      </a>
                      </span>

                    }
                  </div>
                </p>
              </strong>
              <div className='setting-container'>
                <div className="setting-controls">
                  {
                    touched &&
                    <Btn type={'primary'} size={'mini'}
                      onClick={e => this.saveReport()}>
                      <i className="material-symbols-outlined">save</i> Save
                    </Btn>
                  }
                  {
                    !!historyStack.length &&
                    <Btn type={'reset'} size={'mini'}
                      onClick={e => this.rollback()}>
                      <i className="material-symbols-outlined">undo</i> Undo
                    </Btn>
                  }
                  <Btn type={'reset'} size={'mini'}
                    onClick={e => this.showDatasourceBox()}>
                    <i className="material-symbols-outlined">dataset</i> Data
                  </Btn>
                  {
                    canDownloadReport &&
                    <StyledDropdown
                      closeOnClick={true}
                      toggler={<span className="dropdown-toggler-inner"><span className="material-symbols-outlined">download</span></span>}>
                      <div className={`menu-item`}>
                        {/*<a href={`/api/downloadReportExcel?id=${report.id}`}>*/}
                        <a onClick={e => this.downloadExcel()}>
                          <i className="fa fa-file-excel-o" aria-hidden="true"></i> Download as Excel
                        </a>
                      </div>
                      <div className={`menu-item`}>
                        <a onClick={e => this.downloadPDF()}>
                          <i className="material-symbols-outlined">picture_as_pdf</i> Download as PDF
                        </a>
                      </div>
                      {/*<div className={`menu-item`}>*/}
                      {/*  <a onClick={e => this.downloadPDF()}>*/}
                      {/*    <i className="material-symbols-outlined">picture_as_pdf</i> Download as PNG*/}
                      {/*  </a>*/}
                      {/*</div>*/}
                    </StyledDropdown>
                  }
                  <StyledDropdown
                    closeOnClick={true}
                    toggler={<span className="material-symbols-outlined">more_vert</span>}>
                    {
                      canEditTemplate &&
                      <div className={`menu-item`}>
                        <a onClick={e => this.showSaveTemplateBox()}>
                          <i className="material-symbols-outlined">save_as</i>
                          Save{(template && template.id && template.id !== 'empty') ? ' ' : ' as '}Template
                        </a>
                      </div>
                    }
                    {
                      canShareReport &&
                      <div className={`menu-item`}>
                        <a onClick={e => this.showShareBox()}>
                          <i className="material-symbols-outlined">share</i> Share
                        </a>
                      </div>
                    }
                    <div className={`menu-item`}>
                      <Link to={`/report/view?id=${report.id}&code=session_id`} target="_blank">
                        <i className="material-symbols-outlined">preview</i> Preview
                      </Link>
                    </div>
                    {
                      canDeleteReport &&
                      <div className={`menu-item`}>
                        <a onClick={e => this.deleteReport()}>
                          <i className="fa fa-trash"/> Delete
                        </a>
                      </div>
                    }
                  </StyledDropdown>
                </div>
              </div>

            </div>
            <div className="grid-wrapper">
              {this.renderDashboard('overall', dashboard.overall, {
                reportHeader: report.name,
                alertHeader: (alerts || []).map(alert => {
                  return (
                    <AlertBox key={alert.id}>
                      <div className="title" dangerouslySetInnerHTML={{__html: alert.title}}></div>
                      <div className="desc" dangerouslySetInnerHTML={{__html: alert.details.replace('Please change campaign settings according to the change.', '').replace('You can change the MSQ If there is a new MSQ is created for this campaign', '')}}></div>
                      {
                        canSetupCampaign && alert.recipients.indexOf(session.userId) >= 0 && alert.status === 'NEED_RESOLVE' &&
                        <div className="actions">
                          <Link className="button" to={`/campaign/settings?id=${campaign.id}`}>
                            <Btn type="primary" size="mini">
                              {alert.data?.msq?.mbfStatus === 'Terminated' ? 'Change MSQ' : 'Update settings'}
                            </Btn>
                          </Link>
                        </div>
                      }
                    </AlertBox>
                  )
                })
              })}
              {this.renderVisPanel({showForallPlatforms: true, groupByPlatform: true})}
            </div>
          </Wrapper>
        }
      </MainLayout>
    )
  }

  showShareBox() {
    const {session = {}} = this.props.appState || {};
    const {report} = this.state;
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>Share Report</strong>,
      width: '900px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: (
        <ShareSettings
          report={report}
          operator={session.userId}
          onCancel={() => {
            CondirmDialog.closeAll();
          }}
          onChange={shares => {
            report.shares = shares;
            this.setState({report, touched: true}, () => this.saveReportSilent());
            CondirmDialog.closeAll();
          }}
        />
      ),
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />)
  }

  showDatasourceBox() {
    const {report, template} = this.state;
    const confirmInfo = {
      type: 'form',
      backgroundClose: !!report.datasource,
      title: <strong>Select Report Data Source</strong>,
      width: '900px',
      hideCancel: true,
      hideOK: true,
      noCloseBtn: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: (
        <Datasource
          template={template}
          report={report}
          onCancel={() => {
            CondirmDialog.closeAll();
            if(!report.datasource) {
              this.props.history.push(`/reporting`);
            }
          }}
          onChange={datasource => {
            CondirmDialog.closeAll();
            report.datasource = datasource;
            this.setState({report, touched: true}, () => this.saveReportSilent(() => {
              this.setState({data: null, error: null}); //trigger reload
            }));
        }}/>
      ),
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />)
  }

  showSaveTemplateBox() {
    const {report, template} = this.state;
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>Save{(template && template.id && template.id !== 'empty') ? ' ' : ' as '}Template</strong>,
      width: '900px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: (
        <Template
          report={report}
          template={template}
          validate={true}
          onCancel={() => {
            CondirmDialog.closeAll();
          }}
          onChange={template => {
            this.saveTemplate(template);
            CondirmDialog.closeAll();
          }}
        />
      ),
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />)
  }

  showSaveSuccesBox() {
    const {report, template} = this.state;
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: '',
      width: '600px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: (
        <SaveReportDailogWrapper>
          <div className='subhead'><b>{report.name}</b> saved successfully.</div>
          <div className='buttons'>
            <button className='primary' onClick={() => {
              this.setState({touched: false});
              CondirmDialog.closeAll()}
            }>Okay</button>
          </div>
        </SaveReportDailogWrapper>
      ),
    }
    CondirmDialog.defaultRoot.render(<CondirmDialog {...confirmInfo} />)
  }

  saveReportSilent(callback) {
    const {report, dashboard} = this.state;
    report.sections = (dashboard.overall.sections);
    let updateMode = !!report.id;
    post(`/api/${updateMode ? 'updateReport' : 'createReport'}`, report).then(result => {
      if(!updateMode) {
        this.props.history.push(`/report/builder?id=${result.report.id}`);
      }
      callback && callback();
    }).catch(e => {
      this.setState({error: e.message});
    });
  }

  saveReport(stayAfterSave) {
    const {report} = this.state;
    this.setState({loading: true});
    let updateMode = !!report.id;
    post(`/api/${updateMode ? 'updateReport' : 'createReport'}`, report).then(result => {
      this.setState({loading: false});
      setTimeout(() => {
        this.showSaveSuccesBox();
      }, 1000)
      if(!updateMode && stayAfterSave) {
        this.props.history.push(`/report/builder?id=${result.report.id}`);
        this.setState({report: {...report, id: result.report.id}})
      }
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });

  }

  saveTemplate(template) {
    this.setState({loading: true});
    post(`/api/${(template.id && template.id !== 'empty') ? 'update' : 'create'}Template`, template).then(result => {
      this.setState({loading: false, template: template});
      CondirmDialog.showAlert('Template Saved');
    }).catch(e => {
      this.setState({loading: false, error: e.message});
    });
  }



  deleteReport() {
    const {report} = this.state;
    CondirmDialog.showConfirm(`Do you really want to delete this report ?`, () => {
      this.setState({loading: true});
      post('/api/deleteReport', {id: report.id}).then(result => {
        if (result.success) {
          this.props.history.push(`/reporting`);
        } else {
          this.setState({loading: false, error: 'failed'});
        }
      }).catch(e => {
        this.setState({loading: false, error: e.message});
      });
    });
  }
}))
